<script setup lang="ts">
  interface Props {
    title: string
    data: {
      // Catalog letter
      [key: string]: {
        id: number;
        // url title
        title: string;
        url: string;
        type: string;
      }[];
    }
  }

  defineProps<Props>();

  const showAll = ref(false);
</script>

<template>
  <div class="main-container catalog-container">
    <span class="catalog-title">{{ title }}</span>
    <div class="catalog-items" :class="{ hidden: !showAll }">
      <div
        v-for="(items, letter) in data"
        :key="letter"
        class="catalog-item"
      >
        <span class="bold-letter">{{ letter }}</span>
        <div class="catalog-item-links">
          <NuxtLink
            v-for="item in items"
            :key="item.title"
            :to="`/${item.url}`"
            class="link"
          >
            {{ item.title }}
          </NuxtLink>
        </div>
      </div>
      <div v-if="!showAll" class="gradient"></div>
    </div>
    <button type="button" class="catalog-button" @click="showAll=!showAll">
      {{ showAll ? 'Свернуть' : 'Показать все' }}
    </button>
  </div>
</template>

<style scoped lang="scss">
.hidden {
  height: 184px;
  overflow: hidden;
}
.gradient {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 144px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
}
.catalog-container {
  display: flex;
  flex-direction: column;
}
.catalog-title {
  color: #262633;
  font-family: 'Roboto';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 28px;
  @media (max-width: 1023px) {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 16px;
  }
}
.catalog-items {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 22px;
  @media (max-width: 767px) {
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 24px;
  }
}
.catalog-item {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 276px;
  @media (max-width: 1023px) {
    width: 334px;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
}
.bold-letter {
  color: #262633;
  font-feature-settings: 'liga' off;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.09px;
}
.catalog-item-links {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.link {
  text-decoration: none;
  color: #2D7FF9 !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.035px;
}
.catalog-button {
  height: 44px;
  width: fit-content;
  padding: 0px 24px;
  margin: 0 auto;
  border-radius: 15px;
  background-color: #F2F3F6;
  color: #262633;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}
</style>
