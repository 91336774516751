export default (title: string) => {
  // Возможные значения из бекенда: 'speciality','service','disease', 'doctors'
  switch (title) {
    case 'speciality': return 'Специалисты';
    case 'service': return 'Услуги';
    case 'disease': return 'Заболевания';
    case 'doctors': return 'Врачи';
    default: return '';
  }
};
